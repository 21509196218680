import React from 'react'

const Logo = () => {
  return (
    <span className="logo">
        Bartek Zakrzewski
    </span>
  )
}

export default Logo;